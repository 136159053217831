.ad-container {
    background-color: #f4f4f4;
    /* background-color: var(--color-bg-lighter); */
    margin: 0 auto 4rem;
    /* margin: 0 auto var(--spacing-64) auto; */
    min-height: 6.25rem;
    padding: 0;
    position: relative;
    text-align: center;
    width: 100%;
}

.ad-container:before {
    color: #000;
    /* color: var(--color-black); */
    content: "ANUNCIO";
    display: block;
    font-size: .625rem;
    /* font-size: var(--v3-fs-xxs); */
    font-weight: 500;
    /* font-weight: var(--font-weight-medium); */
    letter-spacing: 1px;
    /* letter-spacing: var(--letter-spacing-large); */
    line-height: 1.2;
    /* line-height: var(--v3-lh-small); */
    position: relative;
    text-align: center;
    top: -1.375rem;
    width: 100%;
}
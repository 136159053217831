body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  background-color: var(--bk);
  /* margin-top: 80px !important; */
  color: #131722;
  color: var(--primary-color);
}

:root {

  --primary-color: #131722;

  --main-border-color: #e0e3eb;

  --header-background: #fff;
  --header-text-color: #131722;

  --table-bg-color: #fff;
  --table-header-color: #787b86;
  --table-text-color: #131722;
  --table-symbol-bg: #f0f3fa;

  --breadcrumb-text-color: #131722;
  --breadcrumb-link-color: #4267b2;

  --footer-background: #fff;
  --footer-text-color: #131722;

  --card1-color: #009947;
  --card2-color: #db1222;
  --card3-color: #000;
  --card4-color: #4267b2;

  --bk: white;
  /* 1c212b */
}



[data-theme='dark'] {

  --primary-color: #d1d4dc;

  --main-border-color: #434651;

  --header-background: #1c212b;
  --header-text-color: #d1d4dc;

  --table-bg-color: #1e222d;
  --table-header-color: #787b86;
  --table-text-color: #d1d4dc;
  --table-symbol-bg: #2a2e39;

  --breadcrumb-text-color: #d1d4dc;
  --breadcrumb-link-color: #4267b2;

  --footer-background: #1c212b;
  --footer-text-color: #d1d4dc;

  --card1-color: #009947;
  --card2-color: #db1222;
  --card3-color: #d1d4dc;
  --card4-color: #4267b2;

  --bk: #131722;
}

footer {
  margin-top: 2rem;
  background-color: #fff;
  background-color: var(--footer-background);
  padding: 10px;
  position: relative;
  color: #131722;
  color: var(--footer-text-color);
  text-align: center;
}

footer p {
  padding: 0;
  margin: 0;
}

#tradingview_widget_wrapper div:nth-child(1) {
  margin-bottom: 2rem;
}

#tradingview_widget_wrapper div:nth-child(2) {
  display: none;
}

#tradingview_widget_wrapper .js-copyright-label {
  display: none;
}

.col {
  margin-bottom: 1rem;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ad-container {
    background-color: #f4f4f4;
    /* background-color: var(--color-bg-lighter); */
    margin: 0 auto 4rem;
    /* margin: 0 auto var(--spacing-64) auto; */
    min-height: 6.25rem;
    padding: 0;
    position: relative;
    text-align: center;
    width: 100%;
}

.ad-container:before {
    color: #000;
    /* color: var(--color-black); */
    content: "ANUNCIO";
    display: block;
    font-size: .625rem;
    /* font-size: var(--v3-fs-xxs); */
    font-weight: 500;
    /* font-weight: var(--font-weight-medium); */
    letter-spacing: 1px;
    /* letter-spacing: var(--letter-spacing-large); */
    line-height: 1.2;
    /* line-height: var(--v3-lh-small); */
    position: relative;
    text-align: center;
    top: -1.375rem;
    width: 100%;
}
/* Style the list */
ul.breadcrumb {
    padding: 10px 16px;
    list-style: none;
    /* background-color: #eee; */
}

/* Display list items side by side */
ul.breadcrumb li {
    display: inline;
    font-size: 18px;
    color: var(--breadcrumb-text-color);
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
    padding: 8px;
    color: var(--breadcrumb-text-color);
    content: "/\A0";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
    color: var(--breadcrumb-link-color);
    text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
    color: var(--breadcrumb-link-color);
    text-decoration: underline;
}
.navigation {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: #fff;
    color: black;
    z-index: 1;
}

.brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
}

.navigation-menu {
    margin-left: auto;
}

.navigation-menu ul {
    display: flex;
    padding: 0;
}

.navigation-menu li {
    /* removes default disc bullet for li tags and applies margin to left & right side */
    list-style-type: none;
    margin: 0 1rem;
}

.navigation-menu li a {
    /* increases the surface area of the anchor tag to span more than just the anchor text */
    text-decoration: none;
    display: block;
    width: 100%;
}

.hamburger {
    /* removes default border on button element */
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #4267b2;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    /* positions the icon to the right and center aligns it vertically */
    position: absolute;
    top: 50%;
    right: 25px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: none;
}

.hamburger:hover {
    background-color: #4267b2;
}

@media screen and (max-width: 768px) {
    .hamburger {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .navigation-menu ul {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .navigation-menu ul {
        /* navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar) */
        position: absolute;
        top: 55px;
        left: 0;
        /* stacks the li tags vertically */
        flex-direction: column;
        /* makes menu span full height and width */
        width: 100%;
        height: calc(100vh - 77px);
        background-color: #4267b2;
    }

    .navigation-menu li {
        /* centers link text and strips off margin */
        text-align: center;
        margin: 0;
    }

    .navigation-menu li a {
        color: white;
        /* increases the surface area of the anchor tag to span the full width of the menu */
        width: 100%;
        padding: 1.5rem 0;
        font-weight: bold;
    }

    .navigation-menu li:hover {
        background-color: #eee;
    }

    .navigation-menu li:hover a {
        color: #4267b2;
    }
}

@media screen and (max-width: 768px) {
    .navigation-menu.expanded ul {
        display: block;
    }
}

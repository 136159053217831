body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bk);
  /* margin-top: 80px !important; */
  color: var(--primary-color);
}

:root {

  --primary-color: #131722;

  --main-border-color: #e0e3eb;

  --header-background: #fff;
  --header-text-color: #131722;

  --table-bg-color: #fff;
  --table-header-color: #787b86;
  --table-text-color: #131722;
  --table-symbol-bg: #f0f3fa;

  --breadcrumb-text-color: #131722;
  --breadcrumb-link-color: #4267b2;

  --footer-background: #fff;
  --footer-text-color: #131722;

  --card1-color: #009947;
  --card2-color: #db1222;
  --card3-color: #000;
  --card4-color: #4267b2;

  --bk: white;
  /* 1c212b */
}



[data-theme='dark'] {

  --primary-color: #d1d4dc;

  --main-border-color: #434651;

  --header-background: #1c212b;
  --header-text-color: #d1d4dc;

  --table-bg-color: #1e222d;
  --table-header-color: #787b86;
  --table-text-color: #d1d4dc;
  --table-symbol-bg: #2a2e39;

  --breadcrumb-text-color: #d1d4dc;
  --breadcrumb-link-color: #4267b2;

  --footer-background: #1c212b;
  --footer-text-color: #d1d4dc;

  --card1-color: #009947;
  --card2-color: #db1222;
  --card3-color: #d1d4dc;
  --card4-color: #4267b2;

  --bk: #131722;
}

footer {
  margin-top: 2rem;
  background-color: var(--footer-background);
  padding: 10px;
  position: relative;
  color: var(--footer-text-color);
  text-align: center;
}

footer p {
  padding: 0;
  margin: 0;
}

#tradingview_widget_wrapper div:nth-child(1) {
  margin-bottom: 2rem;
}

#tradingview_widget_wrapper div:nth-child(2) {
  display: none;
}

#tradingview_widget_wrapper .js-copyright-label {
  display: none;
}

.col {
  margin-bottom: 1rem;
}